import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EmbajadorButton = ({ className }) => {
  const { t } = useTranslation();
  const text = t('embajador.text', '¿Quieres ser embajador?');

  return (
    <Link
      to="/embajadores/"
      className={`embajador-button ${className}`}
      aria-label={text}
    >
      {text}
    </Link>
  );
};

export default EmbajadorButton;
