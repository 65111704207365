// src/helpers/Api.js
import axios from 'axios';

const BASE_URL = 'https://dashboard.gestlifesurrogacy.com/api/page/';

export const getPages = async () => {
  try {
    const response = await axios.get(BASE_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching pages', error);
    throw error;
  }
};

export const getPageBySlug = async (slug) => {
  try {
    // Asegurarse de que el slug esté en el formato correcto
    // Eliminar la barra inicial si existe y construir la URL completa
    const normalizedSlug = slug.startsWith('/') ? slug.slice(1) : slug;
    const apiUrl = `${BASE_URL}${normalizedSlug}`;

    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error(`Error fetching page with slug ${slug}`, error);
    throw error;
  }
};
