// src/components/Pages/Home.jsx

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import BlogSection from '../Section/BlogSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import Banner2 from '../Section/BannerSection/Banner2';
import { getBlogArticles } from '../../helpers/GetBlogArticle';
import Footer from '../Footer';
import ContactMethods from '../ContactMethods/ContactMethods';

export default function Home() {
  const { t, i18n } = useTranslation();

  const baseUrl = 'https://dashboard.gestlifesurrogacy.com';

  const [blogData, setBlogData] = useState([]);
  const [blogLoading, setBlogLoading] = useState(true);
  const [blogError, setBlogError] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const data = await getBlogArticles(1, 3);
        const articles = data.data;

        if (!articles) {
          throw new Error('No se encontraron artículos en la respuesta de la API.');
        }

        const latestThreePosts = articles.slice(0, 3).map((post) => ({
          title: post.title,
          thumbUrl: post.image.startsWith('http') ? post.image : `${baseUrl}/${post.image}`,
          date: new Date(post.date).toLocaleDateString(i18n.language === 'es' ? 'es-ES' : 'en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          excerpt: post.content.replace(/<[^>]+>/g, '').substring(0, 35) + '...',
          btnText: t('learn_more'),
          href: `/blog/${post.slug}/`,
          socialShare: true,
        }));

        setBlogData(latestThreePosts);
      } catch (err) {
        setBlogError(t('blog_error') || 'Error al obtener los artículos del blog.');
        console.error(err);
      } finally {
        setBlogLoading(false);
      }
    };

    fetchBlogData();
  }, [baseUrl, i18n.language, t]);

  const featureListData = [
    {
      iconSrc: '/images/home_1/compassion.svg',
      title: t('feature1_title'),
      subTitle: t('feature1_subtitle'),
    },
    {
      iconSrc: '/images/home_1/excellence.svg',
      title: t('feature2_title'),
      subTitle: t('feature2_subtitle'),
    },
    {
      iconSrc: '/images/home_1/integrity.svg',
      title: t('feature3_title'),
      subTitle: t('feature3_subtitle'),
    },
    {
      iconSrc: '/images/home_1/respect.svg',
      title: t('feature4_title'),
      subTitle: t('feature4_subtitle'),
    },
    {
      iconSrc: '/images/home_1/teamwork.svg',
      title: t('feature5_title'),
      subTitle: t('feature5_subtitle'),
    },
  ];

  const brandData = [
    { imgUrl: '/images/PREMIO 1.jpg', imgAlt: t('brand_alt') },
    { imgUrl: '/images/PREMIO 2.jpg', imgAlt: t('brand_alt') },
    { imgUrl: '/images/PREMIO 3.jpg', imgAlt: t('brand_alt') },
    { imgUrl: '/images/PREMIO 4.jpg', imgAlt: t('brand_alt') },
    { imgUrl: '/images/PREMIO 5.jpg', imgAlt: t('brand_alt') },
    { imgUrl: '/images/MEDALLA31.jpg', imgAlt: t('brand_alt') },
    { imgUrl: '/images/MEDALLA41.jpg', imgAlt: t('brand_alt') },
  ];

  const faqData = [
    {
      title: t('faq1_title'),
      content: t('faq1_content'),
    },
    {
      title: t('faq2_title'),
      content: t('faq2_content'),
    },
    {
      title: t('faq3_title'),
      content: t('faq3_content'),
    },
    {
      title: t('faq4_title'),
      content: t('faq4_content'),
    },
    {
      title: t('faq5_title'),
      content: t('faq5_content'),
    },
  ];

  const awardData = [
    {
      title: t('award1_title'),
      subTitle: t('award1_subtitle'),
      iconUrl: '/images/icons/check-badge-svgrepo-com.svg',
    },
    {
      title: t('award2_title'),
      subTitle: t('award2_subtitle'),
      iconUrl: '/images/icons/check-badge-svgrepo-com.svg',
    },
    {
      title: t('award3_title'),
      subTitle: t('award3_subtitle'),
      iconUrl: '/images/icons/check-badge-svgrepo-com.svg',
    },
    {
      title: t('award4_title'),
      subTitle: t('award4_subtitle'),
      iconUrl: '/images/icons/check-badge-svgrepo-com.svg',
    },
  ];

  const departmentData = [
    {
      title: t('department1_title'),
      iconUrl: '/images/departments/hetero.webp',
      href: t('department1_link'),
    },
    {
      title: t('department2_title'),
      iconUrl: '/images/inclusiva.webp',
      href: t('department2_link'),
    },
    {
      title: t('department3_title'),
      iconUrl: '/images/departments/solteros1.webp',
      href: t('department3_link'),
    },
    {
      title: t('department4_title'),
      iconUrl: '/images/departments/solteras.webp',
      href: t('department4_link'),
    },
    {
      title: t('department5_title'),
      iconUrl: '/images/departments/hiv.webp',
      href: t('department5_link'),
    },
    {
      title: t('department6_title'),
      iconUrl: '/images/departments/precio_u_opt.webp',
      href: t('department6_link'),
    },
  ];

  return (
    <>

      <Helmet>
        <link rel="preload" as="image" href={`${baseUrl}/images/home_1/Carrusel_Home.webp`} />
        <link rel="preload" as="image" href={`${baseUrl}/images/contact/icon_1.svg`} />
        <link rel="preload" as="image" href={`${baseUrl}/images/whatsapp-svgrepo-com.svg`} />
        <link rel="preload" as="image" href={`${baseUrl}/images/email-icon.svg`} />
        <title>{t('home_title')}</title>
        <meta name="description" content={t('meta_description')} />
      </Helmet>
      <Hero
        title={t('welcome')}
        subTitle={t('subtitle')}
        bgColor="linear-gradient(40deg, #EE6FBB, #7BADD3)"
        imgUrl="/images/home_1/familia_gestacion_opt.webp"
        videoBtnText={t('who_we_are')}
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: t('phone'),
            subTitle: '+34 935241582',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: t('whatsapp'),
            subTitle: '+34 935241582',
            iconUrl: '/images/whatsapp-svgrepo-com.svg',
          },
          {
            title: t('email'),
            subTitle: 'info@gestlifesurrogacy.com',
            iconUrl: '/images/email-icon.svg',
          },
        ]}
        btnText={t('contact_us')}
        btnUrl="/contacto/"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={40}
        bottomMd={185}
        bottomLg={60}
        bottomXl={100}
      >
        <FeaturesSection
          sectionTitle={t('features_section_title')}
          data={featureListData}
        />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/Gestlife_team2.webp"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title={t('about_section_title')}
          subTitle={t('about_section_subtitle')}
          featureList={[
            {
              featureListTitle: t('about_feature_list_title'),
              featureListSubTitle: t('read_more'),
            },
          ]}
          linkTo={t('warranties_link')}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={60} topXl={110}>
        <DepartmentSection
          sectionTitle={t('programs_section_title')}
          bgColor="linear-gradient(40deg, #EE6FBB, #7BADD3)"
          data={departmentData}
        />
      </Section>
      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={60} topXl={100}>
        <AwardSection
          sectionTitle={t('award_section_title')}
          data={awardData}
        />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial Section */}
      <Section
        topMd={185}
        topLg={60}
        topXl={100}
        bottomMd={200}
        bottomLg={60}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle={t('testimonial_section_title')}
          sectionTitleDown={t('testimonial_section_subtitle')}
        />
      </Section>
      {/* End Testimonial Section */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgColor="linear-gradient(40deg, #EE6FBB, #7BADD3)"
          imgUrl="/images/home_1/familia_gestacion (1).webp"
          title={t('banner_section_title')}
          subTitle={t('banner_section_subtitle')}
          linkTo="/blog/en-que-consiste-gestacion-subrogada/"
          linkWord={t('learn_more')}
        />
      </Section>
      {/* End Banner Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={60} topXl={80}>
        <FaqSection
          data={faqData}
          sectionTitle={t('faq_section_title')}
          sectionTitleUp={t('faq_section_subtitle')}
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={60} topXl={105}>
        <BlogSection
          sectionTitle={t('blog_section_title')}
          sectionTitleUp={t('blog_section_subtitle')}
          data={blogData}
          loading={blogLoading}
          error={blogError}
        />
      </Section>
      {/* End Blog Section */}
      {/* Start Banner2 Section */}
      <Section bottomMd={60}>
        <Banner2
          bgColor="linear-gradient(40deg, #EE6FBB, #7BADD3)"
          imgUrl="/images/home_1/madre_optimizada.webp"
          title={t('mother_in_surrogacy_title')}
          subTitle={t('mother_in_surrogacy_subtitle')}
          linkTo="/blog/la-madre-en-una-gestacion-subrogada/"
          linkWord={t('learn_more')}
        />
      </Section>
      {/* End Banner2 Section */}
      <ContactMethods />
      <Footer />
    </>
  );
}
