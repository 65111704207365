// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import languages from './config/languages'; // Asegúrate de que esta ruta sea correcta

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',  // Español como idioma predeterminado
    supportedLngs: Object.keys(languages),  // Agrega todos los idiomas disponibles desde languages.js
    debug: true,
    interpolation: {
      escapeValue: false, // React ya escapa los valores por defecto
    },
    detection: {
      order: ['path', 'cookie', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,  // Detecta el idioma en el primer segmento de la URL
      caches: ['cookie'],      // Almacena la preferencia de idioma en una cookie
      // Opcional: Puedes configurar más opciones de detección aquí
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',  // Ruta de los archivos de traducción
    },
  });

export default i18n;
