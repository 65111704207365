// src/config/languages.js

const languages = {
    es: { nativeName: 'Español' },
    en: { nativeName: 'Inglés' },
    pt: { nativeName: 'Portugués' },
    fr: { nativeName: 'Francés' },
    it: { nativeName: 'Italiano' },
    au: { nativeName: 'Inglés de Australia' },
    de: { nativeName: 'Alemán' },
    // Añade más idiomas aquí si es necesario
  };
  
  export default languages;
  