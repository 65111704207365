// src/components/Layout/index.jsx

import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import ContactMethods from '../ContactMethods/ContactMethods';
import Header from '../Header';
import WhatsAppButton from '../Button/WhatsAppButton';

export default function Layout() {
  const { lang } = useParams(); // Obtener el idioma de la URL
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      setShowButton(true);
      removeListeners();
    };

    const events = ['click', 'scroll', 'keydown', 'touchstart'];

    const addListeners = () => {
      events.forEach((event) => {
        window.addEventListener(event, handleUserInteraction, { once: true, passive: true });
      });
    };

    const removeListeners = () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleUserInteraction);
      });
    };

    addListeners();

    return () => {
      removeListeners();
    };
  }, []);

  return (
    <>
      <Header logoSrc="/images/Logotipo_Blanco_mod.svg" variant="cs_heading_color" />
      <Outlet />
      
      {/* Botón de WhatsApp */}
      {showButton && <WhatsAppButton />}
      
    </>
  );
}
