import React from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumb({ title }) {
  // Obtener segmentos de URL de manera estática
  const pathSegments = window.location.pathname
    .split('/')
    .filter(segment => segment !== '');

  // Función para construir la ruta con '/' al final
  const buildPath = (segments) => {
    return `/${segments.join('/')}/`.replace(/\/\/+/g, '/');
  };

  return (
    <div className="container">
      <ol className="breadcrumb text-capitalize">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {pathSegments.map((segment, index) => {
          const segmentsUpToIndex = pathSegments.slice(0, index + 1);
          const path = buildPath(segmentsUpToIndex);
          return (
            <li key={index} className="breadcrumb-item">
              {index < pathSegments.length - 1 ? (
                <Link to={path}>
                  {segment.charAt(0).toUpperCase() + segment.slice(1)}
                </Link>
              ) : (
                <span>{segment.charAt(0).toUpperCase() + segment.slice(1)}</span>
              )}
            </li>
          );
        })}
      </ol>
      <div className="cs_height_18" />
      <h1 className="cs_fs_72 mb-0 text-color-gblue">{title}</h1>
    </div>
  );
}
