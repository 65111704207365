import React from 'react';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import TextWidget from '../Widget/TextWidget';
const menuDataOne = [
  { title: 'Nosotros', href: '/la-agencia-lider-en-gestacion-subrogada-en-espana/' },
  { title: 'Programas', href: '/' },
  { title: 'Paises', href: '/paises-donde-hacer-una-gestacion-maternidad-subrogada/' },
  { title: 'Testimonios', href: '/testimonios/' },
];
const menuDataTwo = [
  { title: 'Blog', href: '/blog/' },
  { title: 'Trabaja en GestLife', href: '/trabaja-en-gestlife/' },
  { title: 'FAQs', href: '/' },
  { title: 'Contacto', href: '/contacto/' },
];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: 'url(/images/degradado_mini.webp)' }}
      >
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="Gestlife <br />Surrogacy" />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Suscribete"
                  subTitle="Para recibir toda la informacion actualizada"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_bg_transparent">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">
              Copyright © 2024 Gestlife. Todos los derechos reservados.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
