import React, { useState } from 'react';
import Rating from '../Rating';

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/face_review2.jpg" alt="Avatar" loading="lazy"/>
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0" loading="lazy">Javier Y Coral</h3>
                <p className="cs_heading_color mb-0">New York, USA</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/face_review.jpg" alt="Avatar" loading="lazy"/>
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Conchi</h3>
                <p className="cs_heading_color mb-0">California, USA</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/face_review3.jpg" alt="Avatar" loading="lazy"/>
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Domingo</h3>
                <p className="cs_heading_color mb-0">Florida</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" loading="lazy"/>
            <p>
            Escogimos Gestlife porque es la que nos daba mayores garantías de éxito. Habíamos intentado un proceso de adopción internacional, pero no llegaron nunca a asignar ningún niño.
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" loading="lazy"/>
            <p>
            El nacimiento fue increíble. Una vez que nos la dieron en nuestros brazos, parecía un sueño, no nos lo creíamos. Yo incluso me maree un montón, me tuvieron que asistir.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" loading="lazy"/>
            <p>
            Nos conocimos, y nunca pensamos que llegaríamos hasta aquí. Nos conocimos, y jamás pensamos en casarnos. Ahora somos una familia.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
      </div>
    </div>
  );
}
