// src/components/Button/WhatsAppButton.jsx

import React from 'react';

const WhatsAppButton = ({ className }) => {
  return (
    <a
      className={`whats_buttom ${className}`}
      target="_blank"
      rel="noopener noreferrer"
      href="https://wa.me/380950584784"
      aria-label="Contacto por WhatsApp"
    ></a>
  );
};

export default WhatsAppButton;
