import React from 'react';
import parse from 'html-react-parser';

export default function BannerSectionStyle5({
  bgColor = 'linear-gradient(180deg, #a8c6e7, #d8a4ca, #f8e3ef)', // Valor predeterminado
  imgUrl,
  title,
  subTitle,
}) {
  // Verifica si imgUrl es válida (no "null" ni URL con "null")
  const isValidImgUrl = imgUrl && imgUrl !== 'null' && !imgUrl.includes('/null');

  // Verifica si subTitle es válido (no es nulo, undefined, ni vacío)
  const isValidSubTitle = subTitle && subTitle.trim() !== '';

  return (
    <section
      className="cs_banner cs_style_5 cs_bg_filed"
      style={{ background: bgColor }} // Cambia backgroundImage a background
    >
      {isValidImgUrl && (
        <div className="cs_banner_img">
          <img src={imgUrl} alt="Banner" />
        </div>
      )}
      <div className="container">
        <div className="cs_banner_text">
          <h1 className="cs_banner_title cs_fs_72">{title}</h1>
          {/* Solo renderiza el subtítulo si es válido */}
          {isValidSubTitle && (
            <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color text-color-grey">
              {parse(subTitle)}
            </p>
          )}
        </div>
      </div>
    </section>
  );
}
