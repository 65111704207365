// src/components/Pages/BlogDetails.jsx

import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Sidebar from '../Sidebar';
import { pageTitle } from '../../helpers/PageTitle';
import { getArticleBySlug } from '../../helpers/GetBlogArticle';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import languages from '../../config/languages';

export default function BlogDetails() {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Obtener el código de idioma desde la URL
  const pathSegments = location.pathname.split('/');
  const languageCode = pathSegments[1];
  const isLanguageValid = languages.hasOwnProperty(languageCode);
  const language = isLanguageValid ? languageCode : 'es';

  // Cambiar el idioma en i18n si es necesario
  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  // Redirigir si el código de idioma en la URL no es válido
  useEffect(() => {
    if (!isLanguageValid && languageCode !== '') {
      navigate(`/blog/${slug}/`);
    }
  }, [isLanguageValid, languageCode, navigate, slug]);

  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Configurar el título de la página cuando el artículo esté cargado
  useEffect(() => {
    if (article && article.title) {
      pageTitle(article.title);
    }
  }, [article]);

  // Obtener el artículo
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const data = await getArticleBySlug(slug, language);

        if (data.locale !== language) {
          // Si el artículo no existe en el idioma solicitado, redirigir o mostrar un mensaje
          navigate(`/${language !== 'es' ? `${language}/` : ''}blog/`);
          return;
        }

        setArticle(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching article', error);
        setIsLoading(false);
        // Redirigir a una página 404 o mostrar un mensaje de error
        navigate('/404');
      }
    };

    fetchArticle();
  }, [slug, language, navigate]);

  if (isLoading) {
    return <p>{t('loading_article')}</p>;
  }

  if (!article) {
    return <p>{t('article_not_found')}</p>;
  }

  const bgUrl = "/images/home_1/degradadobckg.webp";

  return (
    <>
      <Helmet>
        <title>{article.meta_title || article.title}</title>
        <meta name="description" content={article.meta_description || ''} />
      </Helmet>

      <Section
        style={{
          backgroundImage: `url(${bgUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '50px 0',
        }}
      />
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={article.title} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_date">
              {new Date(article.date).toLocaleDateString(
                i18n.language === 'es'
                  ? 'es-ES'
                  : i18n.language === 'fr'
                  ? 'fr-FR'
                  : i18n.language === 'pt'
                  ? 'pt-PT'
                  : i18n.language === 'it'
                  ? 'it-IT'
                  : i18n.language === 'de'
                  ? 'de-DE'
                  : i18n.language === 'au'
                  ? 'en-AU'
                  : 'en-US',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              )}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>{t('share')}:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={`https://dashboard.gestlifesurrogacy.com/${article.image}`}
          alt={article.title}
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              <h2>{article.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </div>
            <Spacing md="85" />
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}
