import React from 'react';

export default function Banner({ bgColor, imgUrl, title, subTitle,linkTo,linkWord }) {
  return (
    <div className="container">
      <div
        className="cs_banner cs_style_1 cs_bg_filed"
        style={{ backgroundImage: bgColor }}
      >
        <img src={imgUrl} alt="Banner" className="cs_banner_img" />
        <h2 className="cs_banner_title cs_white_color cs_fs_72">{title}</h2>
        <p className="cs_banner_subtitle cs_heading_color cs_fs_20 cs_medium m-0 text-color-grey">
          {subTitle}
        </p>
        <a className='cs_white_color cs_medium' href={linkTo}>{linkWord}</a>
      </div>
    </div>
  );
}
