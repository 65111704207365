import React from 'react';

// Lista de colores de fondo
const backgroundColors = ['#f8c0e1', '#e8c5e3', '#d6c3e0', '#ccd0ea', '#d6e6f2'];

export default function Feature({ iconSrc, title, subTitle, index }) {
  // Seleccionar el color de fondo basado en el índice
  const backgroundColor = backgroundColors[index % backgroundColors.length];

  return (
    <div className="cs_feature cs_style_1 cs_shadow_1 cs_radius_25" style={{ backgroundColor }}>
      <h2 className="cs_feature_title cs_semibold cs_fs_40 cs_center text-color-grey">
        <span className="cs_feature_icon cs_accent_bg cs_center rounded-circle">
          <img src={iconSrc} alt="Icon" />
        </span>
        <span>{title}</span>
      </h2>
      <p className="m-0 text-center text-color-grey">{subTitle}</p>
    </div>
  );
}