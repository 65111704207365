// src/index.js

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';
import './i18n';
import { TranslationProvider } from './contexts/TranslationContext';

const AppWrapper = () => {
  const { t, i18n } = useTranslation();

  // Detecta si la URL contiene un prefijo de idioma
  const isLanguageInURL = /^\/(en|pt|fr|it|de|au)/.test(window.location.pathname);
  const currentLanguage = isLanguageInURL ? i18n.language : 'es';

  // Obtener el GTM ID desde las traducciones
  const gtmId = t('gtm_id');

  // Insertar el script de GTM directamente en el head
  useEffect(() => {
    if (!gtmId) {
      console.error('gtm_id no está definido en las traducciones.');
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [gtmId]);

  // Insertar el noscript en el body
  useEffect(() => {
    if (!gtmId) return;

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.insertBefore(noscript, document.body.firstChild);

    return () => {
      document.body.removeChild(noscript);
    };
  }, [gtmId]);

  return (
    <HelmetProvider>
      <App />
    </HelmetProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TranslationProvider>
        <AppWrapper />
      </TranslationProvider>
    </BrowserRouter>
  </React.StrictMode>
);