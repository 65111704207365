// src/components/Sidebar.jsx

import React, { useState, useEffect } from 'react';
import SideMenuWidget from '../Widget/SideMenuWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';
import NewsletterStyle5 from '../Widget/NewsletterStyle5';
import { getBlogArticles } from '../../helpers/GetBlogArticle';

export default function Sidebar() {
  const [recentPosts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = 'https://dashboard.gestlifesurrogacy.com';

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const data = await getBlogArticles(1, 5); // Obtener 5 artículos
        const articles = data.data;

        if (!articles) {
          throw new Error('No se encontraron artículos en la respuesta de la API.');
        }

        const mappedPosts = articles.slice(0, 5).map((post) => ({
          title: post.title,
          author: post.author || 'James Brown', // Si no hay autor, usar 'James Brown'
          date: new Date(post.date).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          href: `/blog/${post.slug}/`,
        }));

        setRecentPosts(mappedPosts);
      } catch (err) {
        setError('Error al obtener los artículos recientes.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentPosts();
  }, [baseUrl]);

  return (
    <div className="cs_sidebar">
      <div className="cs_sidebar_item">
        {loading ? (
          <div>Cargando artículos recientes...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <RecentPostWidget title="Artículos nuevos" data={recentPosts} />
        )}
      </div>
      <div className="cs_sidebar_item widget_categories">
        <NewsletterStyle5 title="Newsletter Sign Up Form" />
      </div>
    </div>
  );
}
