import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactMethods = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="contact-methods-container">
      {/* Llamar por teléfono */}
      <div className="contact-item-1">
        <a href="tel:+34935241582">
          <img
          className='contact-image-1'
            src="/images/contact/movil_u.svg"
            alt="Llamar"
          />
          <p className="pop1 modern-text">{t('call_contact_method')}</p>
          <p className="pop2 modern-text">935241582</p>
        </a>
      </div>

      {/* Agendar cita */}
      <div className="contact-item-2">
        <a href="/agenda/">
          <img
          className='contact-image-2'
            src="/images/contact/calendario_u.svg"
            alt="Agendar cita"
          />
          <p className="pop1 modern-text">{t('calendar_contact_method')}</p>
          <p className="pop2 modern-text">{t('calendar_call_contact_method')}</p>
        </a>
      </div>

      {/* Recibir información por correo electrónico */}
      <div className="contact-item-3">
        <a href="/contacto/">
          <img
          className='contact-image-3'
            src="/images/contact/email_u.svg"
            alt="Correo electrónico"
          />
          <p className="pop1 modern-text">{t('email_info_contact_method')}</p>
          <p className="pop2 modern-text">{t('email_contact_method')}</p>
        </a>
      </div>

      {/* Recibir información telefónica */}
      <div className="contact-item-4">
        <a href="/contacto/">
          <img
            src="/images/contact/llamada_u.svg"
            alt="Información telefónica"
            className='contact-image-4'
          />
          <p className="pop1 modern-text">{t('call_info_contact_method')}</p>
          <p className="pop2 modern-text">{t('call_phone_contact_method')}</p>
        </a>
      </div>
    </div>
  );
};

export default ContactMethods;